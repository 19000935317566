const APP_CONST = {
  PIE_CHART_1: {
    chart: {
      type: "pie",
      backgroundColor: "transparent",
      animation: {
        duration: 1000,
      },
      margin: [0, 0, 0, 0],
      series: [],
      width: 50,
      height: 50,
    },
    credits: {
      enabled: false,
    },
    title: {
      useHTML: true,
      text: "<i class='sprite folder-blue-a-active'></i>",
      align: "center",
      verticalAlign: "middle",
      y: 17,
      style: {
        fontFamily: "MuseoSans, Helvetica, Arial, Verdana, sans-serif",
        fontWeight: "700",
      },
    },
    legend: {
      enabled: false,
    },
    exporting: {
      enabled: false,
    },
    tooltip: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        borderColor: "#ffffff",
        slicedOffset: 0,
        size: "100%",
        dataLabels: {
          enabled: false,
        },
        states: {
          hover: {
            enabled: false,
          },
        },
      },
    },
  },
  PIE_CHART_2: {
    chart: {
      type: "pie",
      backgroundColor: "transparent",
      animation: {
        duration: 1000,
      },
      margin: [0, 0, 0, 0],
      series: [],
      width: 50,
      height: 50,
    },
    credits: {
      enabled: false,
    },
    title: {
      useHTML: true,
      text: "<i class='sprite file-default'></i>",
      align: "center",
      verticalAlign: "middle",
      y: 17,
      style: {
        fontFamily: "MuseoSans, Helvetica, Arial, Verdana, sans-serif",
        fontWeight: "700",
      },
    },
    legend: {
      enabled: false,
    },
    exporting: {
      enabled: false,
    },
    tooltip: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        borderColor: "#ffffff",
        slicedOffset: 0,
        size: "100%",
        dataLabels: {
          enabled: false,
        },
        states: {
          hover: {
            enabled: false,
          },
        },
      },
    },
  },
  DOC_STATUS: {
    Finalized: {
      color: "text-green",
      bgColor: "bg-green",
      icon: "check-mark-green-m",
      iconxxs: "check-mark-green-xxs",
      iconlg: "check-mark-green-l",
      text: "Finalized",
    },
    Awaiting_Review: {
      color: "text-medium-gray",
      bgColor: "bg-medium-gray",
      icon: "hourglass-gray-m",
      iconxxs: "hourglass-gray-xxs",
      iconlg: "hourglass-gray-l",
      text: "Awaiting review",
    },
    Flagged_by_QC: {
      color: "text-orange",
      bgColor: "bg-orange",
      icon: "flag-orange-m",
      iconxxs: "flag-orange-xxs",
      iconlg: "flag-orange-l",
      text: "Flagged by QC",
    },
    Note: {
      color: "text-medium-gray",
      bgColor: "bg-medium-gray",
      icon: "hourglass-gray-m",
      iconxxs: "hourglass-gray-xxs",
      iconlg: "hourglass-gray-l",
      text: "Note",
    },
    Unpublished: {
      color: "text-medium-dark-gray",
      bgColor: "bg-medium-gray",
      icon: "prohibited-dark-gray-m",
      iconxxs: "prohibited-dark-gray-xs",
      iconlg: "prohibited-dark-gray-m",
      text: "Unpublished",
    },
    Not_classified: {
      color: "text-medium-dark-gray",
      bgColor: "bg-medium-gray",
      icon: "prohibited-dark-gray-m",
      iconxxs: "prohibited-dark-gray-xs",
      iconlg: "prohibited-dark-gray-l",
      text: "Not classified",
    },
    Note: {
      color: "text-medium-blue",
      bgColor: "bg-medium-blue",
      icon: "note-blue-m",
      iconxxs: "note-blue-xs",
      iconlg: "note-blue-l",
      text: "Note",
    },
    required: {
      color: "text-pink",
      bgColor: "bg-pink",
      icon: "times-round-pink-m",
      iconxxs: "times-round-pink-xxs",
      iconlg: "times-round-pink-l",
      text: "Removed",
    },
    uploaded: {
      color: "text-medium-dark-gray",
      bgColor: "bg-medium-gray",
      icon: "prohibited-dark-gray-m",
      iconlg: "prohibited-dark-gray-l",
      text: "Uploaded",
    },
    Scanning: {
      color: "text-medium-dark-gray",
      bgColor: "bg-medium-gray",
      icon: "prohibited-dark-gray-m",
      iconlg: "prohibited-dark-gray-l",
      text: "Scanning...",
    },
    Recalled: {
      bgColor: "prohibited-red",
      icon: "prohibited-red-m",
      iconlg: "prohibited-red-l",
      iconxxs: "prohibited-red-xxs",
    },
  },
  BLACK_LISTED_FILE_FORMATS: [
    "action",
    "apk",
    "app",
    "bat",
    "bin",
    "cmd",
    "com",
    "command",
    "cpl",
    "csh",
    "exe",
    "gadget",
    "inf1",
    "ins",
    "inx",
    "ipa",
    "isu",
    "job",
    "jse",
    "ksh",
    "lnk",
    "msc",
    "msi",
    "msp",
    "mst",
    "osx",
    "out",
    "paf",
    "pif",
    "prg",
    "ps1",
    "reg",
    "rgs",
    "run",
    "scr",
    "sct",
    "shb",
    "shs",
    "u3p",
    "vb",
    "vbe",
    "vbs",
    "vbscript",
    "workflow",
    "ws",
    "wsf",
    "wsh",
    "jad",
    "jar",
    "asp",
    "aspx",
    "cer",
    "cfm",
    "cgi",
    "pl",
    "css",
    "js",
    "jsp",
    "part",
    "php",
    "py",
    "rss",
    "xhtml",
    "jsx",
    "scss",
    "regex",
    "ts"

  ],
  ALLOW_TO_UPLOAD: ["WRITE", "SIGN"],
  FILE_TYPES: {
    doc: ["doc", "docx"],
    xls: ["xls", "xlsx", "csv", "ods"],
    ppt: ["ppt", "pptx"],
    zip: ["zip"],
    default: ["txt"],
  },
  AVAILABLE_ICONS: ["doc", "xls", "ppt", "pdf", "zip"],
  HISTORY_FILE_FILEDS: [
    "File",
    "File guid",
    "File type",
    "Original file name",
    "File size",
  ],
  USER_DATA: {
    importedBy: "3fa85f64-0000-4562-b3fc-2c963f66afa6",
    userId: "3fa85f64-0000-4562-b3fc-2c963f66afa6",
  },
  HARD_CODED: {
    templateId: "5d4cefab-3a38-40c8-b2f4-5acd4353fede",
    dummyId: "00000000-0000-0000-0000-000000000000",
  },
  DOC_STATUS_ARR: [
    "Finalized",
    "Awaiting review",
    "Flagged by QC",
    "Unpublished",
    "unpublished",
  ],
  DOC_STATUS_MAP: (val) => {
    switch (val) {
      case "Finalized":
        return "Finalized";
      case "Awaiting review":
        return "Awaiting_Review";
      case "Flagged by QC":
        return "Flagged_by_QC";
      case "required":
        return "required";
      case "Scanning in progress":
        return "Scanning";
      case "Note":
        return "Note";
      case "Not classified":
        return "Not_classified";
      case "shared":
        return "shared";
      case "private":
        return "private";
      case "Recalled":
        return "Recalled";
      default:
        return "Unpublished";
        break;
    }
  },
  FILE_SIZE: {
    TENMB: 10000000,
  },
  TIMEOUT: {
    FIVEMS: 5,
    TENMS: 10,
    TWENTYMS: 20,
  },
  TITLE_ARRAY: ["artifactName", "subArtifactName", "customTypeName"],
  TMF_LEVELS: [
    {
      id: "8ab3bc74-5b5e-43f8-9bab-2b693530a310",
      description: "Site",
    },
    {
      id: "93863545-b158-40c9-a5bc-ac9b688cf438",
      description: "Trial",
    },
    {
      id: "be494877-b66c-40f0-bfe8-f273a165fc55",
      description: "Country",
    },
  ],
  DEFAULT_PAGE_INFO: {
    total: 0,
    perPage: 20,
    activePage: 1,
    from: 0,
    to: 19,
  },
  ADMIN_TEMPLATE_STATUS: ["Selected", "Instantiated", "Maintenance"],
  ALLOW_TO_SELECT_STATUS: ["Instantiated", "Maintenance"],
  VIEW_PER_PAGE: [20, 50, 100],
  ROLES_OPTIONS: [
    { id: "NO ACCESS", name: "NO ACCESS" },
    { id: "READ", name: "READ" },
    { id: "WRITE", name: "WRITE" },
    { id: "REVIEW", name: "REVIEW" },
  ],
  DUMMY_MILESTONES_OPTIONS: [
    { value: "Option 1", label: "Option 1" },
    { value: "Option 2", label: "Option 2" },
    { value: "Option 3", label: "Option 3" },
  ],
  FORM_CURRENT_ACTION_ID: [2, 3, 4],
  FORM_CURRENT_ACTION_OBJECT: {
    2: "Added new",
    3: "Edited",
    4: "Deleted",
    5: "Added new",
    6: "Edited",
    7: "Deleted",
    8: "Edited",
    9: "Edited",
  },
  MILESTONE_OPTIONS: [
    { value: "Start Up" },
    { value: "Study Conduct" },
    { value: "Close Out" },
    { value: "Other" },
  ],
  MILESTONE_GROUP_LIST: ["Start Up", "Study Conduct", "Close Out", "Other"],
  ADMIN: {
    TEMPLATES: "Templates",
    SETTINGS: "Settings",
    TMFSTRUCTURE: "TMF structure",
    STATUS: "Status",
    STRUCTURE: "Structure"
  },
  SIGNATURE_REASON: [
    { label: "I have read and understood the contents of the file.", value: "I have read and understood the contents of the file." },
    { label: "I attest the accuracy of this file.", value: "I attest the accuracy of this file." },
    { label: "I have reviewed and approve this file.", value: "I have reviewed and approve this file." },
    { label: "I agree to the file.", value: "I agree to the file." },
    { label: "I attest the accuracy of the wet ink signature.", value: "I attest the accuracy of the wet ink signature." }
  ],
  SUBJECT_SIGNATURE_REASON: [
    { label: "I confirm that I have read and understand the contents of the document. I agree to take part in the study.", value: "I confirm that I have read and understand the contents of the document. I agree to take part in the study.", id: 1 },
  ],
  COSIGNATORY_REASON: [
    { label: "I certify that the participant signing this document had the study fully and carefully explained to him/her by me.", value: "I certify that the participant signing this document had the study fully and carefully explained to him/her by me." }
  ],
  SHARING: {
    CLINICALUSER: "Viedoc Clinic users",
    VIEDOCMEUSERS: "Viedoc Me users"
  },
  WEBVIEW_DISABLED_ELEMENT: [
    'applyFormFieldButton',
    'eraserToolButton',
    'freeHandHighlightToolGroupButton',
    'freeHandToolGroupButton',
    'freeTextToolGroupButton',
    'highlightToolGroupButton',
    'leftPanelButton',
    'menuButton',
    'panToolButton',
    'redoButton',
    'ribbon',
    'ribbons',
    'searchButton',
    'selectToolButton',
    'shapeToolGroupButton',
    'signatureFieldToolGroupButton',
    'SignatureFormField',
    'squigglyToolGroupButton',
    'stickyToolGroupButton',
    'strikeoutToolGroupButton',
    'toggleNotesButton',
    'toolbarGroup-Annotate',
    'toolbarGroup-Edit',
    'toolbarGroup-FillAndSign',
    'toolbarGroup-Forms',
    'toolbarGroup-Insert',
    'toolbarGroup-Shapes',
    'toolbarGroup-View',
    'toolsHeader',
    'toolsOverlay',
    'underlineToolGroupButton',
    'undoButton',
    'viewControlsButton',
    'addReplyAttachmentButton',
    'annotationCommentButton',
    'annotationStyleEditButton',
    'copyTextButton',
    'documentControl',
    'freeHandHighlightToolButton',
    'freeHandToolButton',
    'freeTextToolButton',
    'highlightToolButton',
    'linkButton',
    'markInsertTextToolButton',
    'markReplaceTextToolButton',
    'multiDeleteButton',
    'multiGroupButton',
    'multiReplyButton',
    'multiSelectModeButton',
    'multiStateButton',
    'multiStyleButton',
    'notePopup',
    'noteState',
    'pageManipulationOverlayButton',
    'stickyToolButton',
    'textHighlightToolButton',
    'textSquigglyToolButton',
    'textStrikeoutToolButton',
    'textUnderlineToolButton',
    'thumbDelete',
    'thumbRotateClockwise',
    'zoomOverlay'
  ],
};

export const DURATION = 120000;

export default APP_CONST;